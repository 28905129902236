import React, { ReactElement } from 'react';
import ErrorComponent from 'components/pages/error/error';
import { getPageProps } from 'utils/helpers/server/serverSideProps/ssrProps';
import { ServerSideInitProps } from 'types/types';
import { getPageNamespaces } from 'utils/helpers/server/serverSideProps/translations';

const Custom404 = (): ReactElement => <ErrorComponent errorCode={404} />;

//eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

interface ServerSideProps {
    props?: Props;
}

export async function getStaticProps({
    req,
}: ServerSideInitProps): Promise<ServerSideProps> {
    const props = await getPageProps<Props>({
        givenProps: {},
        req,
        loadUserData: false,
        loadMenuData: false,
        nameSpaces: getPageNamespaces(),
    });

    return {
        props,
    };
}

export default Custom404;
