import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { getRoute } from 'constants/routes';
import Button from 'components/forms/Button';
import H1 from 'components/typography/H1';
import H2 from 'components/typography/H2';
import { convertDisciplineToTheme } from 'utils/helpers/browser/theme';
import { getDiscipline } from 'utils/helpers/browser/discipline';
import Head from 'components/layouts/Head';
import Logo from 'components/logo/Logo';
import styles from './error.module.scss';

interface Props {
    errorCode: number;
}

const Error = ({ errorCode }: Props): ReactElement => {
    const Router = useRouter();
    const theme = convertDisciplineToTheme(getDiscipline());

    return (
        <div className={styles.root}>
            <Head title={errorCode + ''} theme={theme} />
            <Logo />
            <div className={styles.container}>
                <H1>{'Chyba ' + errorCode ? errorCode : ''}</H1>
                <H2>
                    {errorCode === 404
                        ? ('Stránka nenalezena.' as string)
                        : ('Vyskytla se chyba serveru.' as string)}
                </H2>
                <div className={styles.buttons}>
                    {errorCode !== 404 && (
                        <Button onClick={() => Router.reload()}>
                            {'Znova načíst stránku' as string}
                        </Button>
                    )}
                    <Button onClick={() => Router.push(getRoute('/'))}>
                        {'Návrat na domovskou stránku' as string}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Error;
